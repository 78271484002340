@mixin page-home($profile, $posts) {
  .home {
    @if $profile {
      .home-profile {
        @include transform(translateY(if($posts, 0, 16vh)));
        padding: if($posts, 2rem, 0) 0 .5rem;
        text-align: center;

        .home-avatar {
          padding: 0.6rem;

          img {
            display: inline-block;
            width: if($posts, 6rem, 8rem);
            height: auto;
            margin: 0 auto;
            @include border-radius(100%);
            @include box-shadow(0 0 0 .3618em rgba(0, 0, 0, .05));
            @include transition(all 0.4s ease);

            &:hover {
              position: relative;
              @include transform(translateY(-.75rem));
            }
          }
        }

        .home-title {
          font-size: 1.2rem;
          font-weight: bold;
          margin: 0;
          padding: .4rem;
        }

        .home-subtitle {
          font-size: 1rem;
          font-weight: normal;
          margin: 0;
          padding: .4rem;
        }

        .social-links {
          padding: .4rem .2rem;
          font-size: 1.6rem;

          a * {
            vertical-align: text-bottom;
          }

          img {
            height: 1.4rem;
            padding: 0 .25rem;
          }
        }

        .home-disclaimer {
          font-size: 1rem;
          line-height: 1.4rem;
          font-weight: normal;
          margin: 0;
          padding: .4rem;
          color: $global-font-secondary-color;

          [theme=dark] & {
            color: $global-font-secondary-color-dark;
          }
        }
      }
    }

    @if $posts {
      @include summary;
    }
  }
}

@include page-home($home-profile, $home-posts);
